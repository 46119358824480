import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function HorizontalLinearAlternativeLabelStepper({
  steps,
  activeStep
}: any) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label: any) => (
          <Step key={label.headText}>
            <StepLabel>
              <span className="headtext">{label.headText}</span>
              {/* <span className="subtext">{label.subText}</span> */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
